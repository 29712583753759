<template>
  <div>
    <div v-if='!transaction || failed' class="border-bottom pb-6 mb-6">
      <v-btn data-cy-register-transaction @click='register_transaction' :loading='registering' block class="r---button r---button-primary">
        {{ $t('checkout_payment_button_pay_moya') }}
      </v-btn>
      <v-alert  v-if='failed' cy-payment-failed-alert text type='error' class="r---alert mt-5 mb-0">
        {{ failed }}
      </v-alert>
    </div>

    <div v-if='processing' class='d-flex flex-column align-center justify-center py-4'>
      <p class='mt-4'>{{ $t('checkout_payment_wrapping_up') }}</p>
      <v-progress-circular indeterminate color="primary" />
    </div>

    <div class='text-center my-6'>
      <img :src='require(`@/assets/payment/moyapay_primary.png`)' alt='moyapay' class='img-fluid' style='max-height: 35px;'>
    </div>
    <div class='text-center my-6'>
      <img :src='require(`@/assets/payment/moyapay_array.png`)' alt='moyapay_providers' class='img-fluid' style='max-height: 150px;'>
    </div>

    <v-dialog v-model='pending_user_input' eager fullscreen color='primary'>
      <v-card class='d-flex flex-column' style='border-radius: 0;'>
        <v-toolbar color='primary' flat class='primary_text--text flex-grow-0'>
          <v-img contain :src='icon_image_url' max-height='40' max-width='48' class='mr-4'></v-img>
          <v-toolbar-title>{{ application_title }}</v-toolbar-title>
        </v-toolbar>
        <iframe :src='redirect_url' ref='moya' class='flex-grow-1' style='border: 0;'></iframe>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import payment_provider from '@/mixins/payment_provider'
import LoadingSpinner from '@/components/loading_spinner'

export default {
  components: { LoadingSpinner },

  mixins: [payment_provider],

  mounted () {
    window.addEventListener('message', this.iframe_message_handler)
    this.register_transaction()
  },

  computed: {
    application_title () {
      return this.$store.getters['orders/transaction_application_title']
    },

    icon_image_url () {
      return this.$store.getters['orders/transaction_icon_image_url']
    },

    pending_user_input() {
      return this.transaction && this.transaction.status == 'pending_user_input'
    },

    processing() {
      return this.transaction && this.transaction.status == 'processing'
    },

    redirect_url() {
      return (
        this.transaction &&
        this.transaction.provider_data &&
        this.transaction.provider_data.redirectUrl
      );
    },
  },

  data () {
    return {
      failed: null,
      portal_open: false,
      registering: false,
      transaction: null,
      error_messages: {
        'default': this.$t('checkout_payment_error_failed'),
        'DECLINED': this.$t('checkout_payment_error_declined'),
        'INSUFFICIENT_FUNDS': this.$t('checkout_payment_error_insufficient_funds'),
        'AMOUNT_ERROR': this.$t('checkout_payment_error_amount')
      }
    }
  },

  methods: {
    register_transaction() {
      this.registering = true
      this.$store.dispatch('orders/register_transaction', { order_id: this.order_id, supplier: this.supplier })
      .then((transaction) => { this.register_transaction_handler(transaction) })
      .catch((error) => { console.error('payment.register_transaction', error) })
      .finally(() => { this.registering = false })
    },

    register_transaction_handler(transaction) {
      this.transaction = transaction
      if (!transaction.provider_data.redirectUrl) { this.failed = this.$t('checkout_payment_error_failed') }
    },

    iframe_message_handler(event) {
      if (event.data === 'transaction-processing') { this.fetch_transaction() }
    },

    // for MOYA transaction poll
    fetch_transaction() {
      this.transaction.status = 'processing'
      if (!this.transaction.id) { return this.delay_fetch_transaction() }
      this.$store.dispatch('orders/fetch_transaction', { id: this.transaction.id, supplier: this.supplier })
      .then((response) => this.handle_transaction_poll(response))
      .catch((error) => console.error('checkout/fetch_transaction: error', error))
    },

    // for MOYA transaction poll
    handle_transaction_poll(response) {
      this.transaction = response
      if (response.status == 'success') { return this.$emit('completed', this.transaction) }

      switch (response.provider_data?.state) {
        case 'CREATED':
          this.delay_fetch_transaction()
          break
        case 'ACCEPTED':
          this.delay_fetch_transaction()
          break
        case 'CANCELED':
          this.user_cancelled()
          break
        // case 'APPROVED':
        //   this.transaction_success()
        //   break
        case 'DECLINED':
          this.transaction_failed(response.provider_data.state)
          break
        case 'INSUFFICIENT_FUNDS':
          this.transaction_failed(response.provider_data.state)
          break
        case 'AMOUNT_ERROR':
          this.transaction_failed(response.provider_data.state)
          break
        default:
          this.delay_fetch_transaction()
      }
    },

    // for MOYA transaction poll
    delay_fetch_transaction() {
      this.transaction_poll_timeout = setTimeout(() => this.fetch_transaction(), 1000)
    },

    // for MOYA transaction poll
    user_cancelled() {
      this.failed = this.$t('checkout_payment_user_cancelled')
      this.transaction = null
    },

    transaction_failed(error='default') {
      this.failed = this.error_messages[error]
    },

  }
}
</script>

<style>

</style>
