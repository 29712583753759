
import payment_provider from '@/mixins/payment_provider'
import QRCode from 'qrcode'

export default {
  mixins: [payment_provider]

  data: -> {
    selection: "qr"
    transaction: null
  }

  computed: {
    payment_url: ->
      @transaction?.provider_data?.redirect_url
  }

  mounted: ->
    @register_transaction()

  methods:
    register_transaction: ->
      @loading = true
      @transaction = null
      @failed = null
      @$store
        .dispatch("orders/register_transaction", order_id: @order_id, supplier: @supplier)
        .then (transaction) =>
          @register_transaction_handler transaction
        .catch (error) =>
          console.error "orders/register_transaction", error
        .finally =>
          @loading = false

    register_transaction_handler: (transaction) ->
      @transaction = transaction
      @make_qr(transaction) if transaction.status == 'pending_user_input'
      @poll()

    poll: ->
      @poll_timeout = setTimeout =>
        @$store
          .dispatch("orders/fetch_transaction", id: @transaction.id, supplier: @supplier)
          .then (transaction) =>
            @poll_handler transaction
          .catch (error) =>
            console.error "payment.poll", error
          .finally =>
            @loading = false
      , 2000

    poll_handler: (transaction) ->
      @transaction = transaction
      if transaction.status is "failed"
        @failed = transaction.provider_data.displayMessage
      else if transaction.status is "success"
        @$emit "completed", transaction
      else
        @poll()

    make_qr: (transaction) ->
      QRCode.toCanvas @$refs.qr_canvas, transaction.provider_data.redirect_url, (error) ->
        return console.error(error) if error
  }
