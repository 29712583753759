<template>
  <div class="ma-4 pa-4">
    <v-dialog
      v-if="requires_user_input"
      v-model="requires_user_input"
      eager
      fullscreen
      color="primary"
    >
      <v-card
        id="cart-payment"
        color="primary"
        class="d-flex flex-column"
        style="border-radius: 0"
      >
        <!-- <v-toolbar color='primary' flat class='primary_text--text flex-grow-0'>
          <v-img contain :src='icon_image_url' max-height='40' max-width='48' class='mr-4'></v-img>
          <v-toolbar-title>{{ this.$store.state.application.meta.title }}</v-toolbar-title>
        </v-toolbar> -->
        <iframe :src="redirect_url" class="flex-grow-1" style="border: 0"></iframe>
      </v-card>
    </v-dialog>

    <div
      v-else-if="is_processing"
      cy-payment-processing
      class="d-flex flex-column align-center justify-center py-4"
    >
      <p class="mt-4">{{ $t("checkout_payment_wrapping_up") }}</p>
      <v-progress-circular indeterminate color="primary" />
    </div>

    <v-alert
      v-if="failed"
      cy-payment-error
      text
      type="error"
      class="r---alert my-4"
    >
      {{ failed }}
    </v-alert>

    <v-btn
      v-if="!transaction || failed"
      data-cy-register-transaction
      @click="register_transaction"
      :disabled="loading"
      block
      class="r---button r---button-primary mt-4"
    >
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
        size="20"
      />
      {{ $t("payment_yoco_register_transaction") }}
    </v-btn>

    <div class="r---payment-row">
      <div class="r---payment-card">
        <img
          :src="require(`@/assets/payment/mastercard.jpg`)"
          alt="mastercard"
          height="41px"
          style="margin-bottom: 2px"
        />
      </div>
      <div class="r---payment-card">
        <img
          :src="require(`@/assets/payment/visa.jpg`)"
          alt="visa"
          height="26px"
        />
      </div>
      <div class="r---payment-card">
        <img
          :src="require('@/assets/payment/yoco.svg')"
          alt="yoco"
          height="32px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { number_to_currency } from "@/lib/helpers";
import payment_provider from "@/mixins/payment_provider";

export default {
  mixins: [payment_provider],

  created() {
    window.addEventListener("message", this.window_message_handler, false);
  },

  mounted() {
    this.register_transaction();
  },

  beforeDestroy() {
    if (this.poll_timeout) {
      clearTimeout(this.poll_timeout);
    }
  },

  data() {
    return {
      card_validation: null,
      loading: false,
      requesting_token: false,
      transaction: null,
      widget: null,
      failed: null,
    };
  },

  computed: {
    redirect_url: function () {
      return (
        this.transaction &&
        this.transaction.provider_data &&
        this.transaction.provider_data.redirect_url
      );
    },

    formatted_amount: function () {
      return number_to_currency({
        cents: this.transaction.amount_in_cents,
        symbol: this.$store.getters["application/currency"],
      });
    },

    is_processing: function () {
      return this.transaction && this.transaction.status === "processing";
    },

    requires_user_input: function () {
      return (
        this.transaction && this.transaction.status === "pending_user_input"
      );
    },
  },

  methods: {
    register_transaction() {
      this.loading = true;
      this.transaction = null;
      this.failed = null;
      this.$store
        .dispatch("orders/register_transaction", {
          order_id: this.order_id,
          supplier: this.supplier,
        })
        .then((transaction) => {
          this.register_transaction_handler(transaction);
        })
        .catch((error) => {
          console.error("orders/register_transaction", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    register_transaction_handler(transaction) {
      this.transaction = transaction;
      this.poll();
    },

    poll() {
      this.poll_timeout = setTimeout(() => {
        this.$store
          .dispatch("orders/fetch_transaction", {
            id: this.transaction.id,
            supplier: this.supplier,
          })
          .then((transaction) => {
            this.poll_handler(transaction);
          })
          .catch((error) => {
            console.error("payment.poll", error);
          })
          .finally(() => {
            this.loading = false;
          });
      }, 2000);
    },

    poll_handler(transaction) {
      this.transaction = transaction;
      if (transaction.status === "failed") {
        this.failed = transaction.provider_data.displayMessage;
      } else if (transaction.status === "success") {
        this.$emit("completed", transaction);
      } else {
        this.poll();
      }
    },

    window_message_handler(event) {
      if (event.data && event.data == "transaction-cancelled") {
        this.transaction.status = "failed";
        this.failed = "You have cancelled the payment process";
      } else {
        console.error("yoco.window_message_handler", event);
      }
    }
  },
};
</script>

<style>
</style>
