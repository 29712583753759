export default {
  props: {
    order_id: {
      type: String,
      required: true
    },
    supplier: {
      type: Object,
      required: true
    }
  },

  methods: {
    register_transaction() {},

    register_transaction_handler() {},

    process_transaction() {},

    process_transaction_handler() {},

    finalize_transaction() {}
  }
}
