
import PageNavbar from '@/components/page_navbar.vue'
import { formatted_datetime } from '@/lib/helpers'
import LoadingSpinner from '@/components/loading_spinner'
import Moya from '@/components/payment/moya.vue'
import PayGate from '@/components/payment/pay_gate.vue'
import Yoco from '@/components/payment/yoco.vue'
import Shop2Shop from '@/components/payment/shop2shop.vue'

export default {
  components: { PageNavbar, LoadingSpinner, Moya, PayGate, Shop2Shop, Yoco },

  created: ->
    @$store.dispatch('orders/fetch', @$router.currentRoute.query.order_id)
    .then (order) =>
      # redirect to order page if order does not require payment
      return @show_provider = true if @$store.getters['orders/requires_online_payment']

      @$store.commit('layout/order_timeout_modal', true) if order.status == 'abandoned'
      @$router.replace("/orders/#{order.id}")
      @$store.dispatch('checkout/reset')
    .catch (error) -> console.error window.location, error

  mounted: ->
    if @is_shop2shop && !@may_pay_shop2shop_at_delivery
      console.error 'may not pay at delivery, autoclick pay_now'

  data: -> {
    shop2shop_modal: false,
    show_provider: false
  }

  computed: {
    formatted_total: ->
      @$store.getters['checkout/formatted_total']

    formatted_collection_date: ->
      formatted_datetime(@$store.state.checkout.collection_date, 'dd MMM y')

    order_id: -> @$router.currentRoute.query.order_id

    may_pay_shop2shop_at_delivery: -> true
  }

  methods: {
    has_provider: (provider) ->
      @$store.getters['orders/has_provider'](provider)

    transaction_success: ->
      @portal_open = false
      @$store.commit('checkout/show_outro', true)
      @$store.dispatch('analytics/purchase')
      @$router.push("/orders/#{@order_id}")
        .then => @$store.dispatch('checkout/reset')

    finalize_order: ->
      @loading = true
      @$store.dispatch('checkout/finalize_order')
      .then (res) =>
        @$store.commit('checkout/show_outro', true)
        @$store.dispatch('analytics/purchase')
        @$router.replace("/orders/#{@order_id}")
        @$store.dispatch('checkout/reset')
      .catch (error) =>
        console.error 'checkout/finalize_order.catch', error
      .finally =>
        @shop2shop_modal = false

    pending_payment: ->
      @loading = true
      @$store.dispatch('checkout/pending_payment')
      .then (res) =>
        @$store.commit('checkout/show_outro', true)
        @$store.dispatch('analytics/purchase')
        @$router.push("/orders/#{@order_id}").then () =>
          @$store.dispatch('checkout/reset')
      .catch (error) =>
        console.error 'checkout/pending_payment.catch', error
      .finally =>
        @shop2shop_modal = false
  }
}
