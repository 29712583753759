<template>
  <div>
    <div class="border-bottom pb-6">
      <v-btn data-cy-register-transaction @click='register_transaction' :loading='registering' block class="r---button r---button-primary">
        {{ $t('checkout_payment_button_pay_paygate') }}
      </v-btn>
      <v-alert  v-if='failed' data-cy-transaction-notice text type='error' class="r---alert mt-5 mb-0">
        {{ failed }}
      </v-alert>
    </div>

    <div class="r---payment-row">
      <div class="r---payment-card"><img :src='require(`@/assets/payment/mastercard.jpg`)' alt='mastercard' height="41px" style="margin-bottom: 2px;"></div>
      <div class="r---payment-card"><img :src='require(`@/assets/payment/mastercard-securecode.jpg`)' alt='mastercard-securecode' height="33px"></div>
      <div class="r---payment-card"><img :src='require(`@/assets/payment/visa.jpg`)' alt='visa' height="26px"></div>
      <div class="r---payment-card"><img :src='require(`@/assets/payment/verified-by-visa.jpg`)' alt='verified-by-visa' height="37px" style="margin-bottom: 1px;"></div>
      <div class="r---payment-card"><img :src='require(`@/assets/payment/paygate-plus.jpg`)' alt='paygate-plus' width="92px"></div>
    </div>

    <v-dialog v-model='pending_user_input' eager fullscreen color='primary'>
      <v-card id='cart-payment' color="primary" class='d-flex flex-column' style='border-radius: 0;'>
        <v-toolbar color='primary' flat class='primary_text--text flex-grow-0'>
          <v-img contain :src='icon_image_url' max-height='40' max-width='48' class='mr-4'></v-img>
          <v-toolbar-title>{{ application_title }}</v-toolbar-title>
        </v-toolbar>
        <form ref='paygate' :action="redirect_url" name="paygate_form" id="paygate_form" method="post" target="iframeID">
          <input type="hidden" name="PAYGATE_ID" :value='paygate_id' />
          <input type="hidden" name="PAY_REQUEST_ID" :value='pay_request_id' />
          <input type="hidden" name="CHECKSUM" :value='checksum' />
        </form>
        <iframe name="iframeID" class='flex-grow-1' style='border: 0;'></iframe>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import payment_provider from '@/mixins/payment_provider'

export default {
  mixins: [payment_provider],

  created() {
    window.addEventListener('message', this.handle_transaction_response)
  },

  destroyed() {
    window.removeEventListener('message', this.handle_transaction_response)
  },

  mounted() {
    this.register_transaction()
  },

  data() {
    return {
      registering: false,
      failed: null,
      transaction: null,
      redirect_url: null,
      paygate_id: null,
      pay_request_id: null,
      checksum: null
    }
  },

  computed: {
    application_title() { this.$store.state.application.meta.title },

    icon_image_url() { this.$store.getters['application/icon_image_url'] },

    pending_user_input: {
      get() { return this.transaction && this.transaction.status === 'pending_user_input' },
      set(v) {}
    }
  },

  methods: {
    handle_transaction_response(event) {
      let message = event.data
      if (message == 'transaction-failed') { this.transaction_failed() }
      if (message == 'transaction-success') { this.$emit('completed', this.transaction) }
    },

    register_transaction() {
      this.registering = true
      this.$store.dispatch('orders/register_transaction', { order_id: this.order_id, supplier: this.supplier })
      .then((transaction) => { this.register_transaction_handler(transaction) })
      .catch((error) => { console.error('payment.register_transaction', error) })
      .finally(() => { this.registering = false })
    },

    register_transaction_handler(transaction) {
      this.transaction = transaction
      this.redirect_url = transaction.provider_data.redirect_url
      this.paygate_id = transaction.provider_data.url_params.PAYGATE_ID
      this.pay_request_id = transaction.provider_data.url_params.PAY_REQUEST_ID
      this.checksum = transaction.provider_data.url_params.CHECKSUM
      this.$nextTick(() => { this.$refs['paygate'].submit() })
    },

    transaction_failed(error='default') {
      this.$store.dispatch('orders/fetch_transaction', { id: this.transaction.id, supplier: this.supplier })
      .then((transaction) => {
        this.transaction = transaction
        this.failed = transaction.provider_data.description
      })
      .catch((error) => { console.error('payment.transaction_failed', error) })
    }
  }
}
</script>

<style>

</style>
